/**
 * Created by slava on 11/10/19
 */

var BuildingView = cc.Node.extend({
    ctor: function (building) {
        this.building = building;

        this._super();

        this.setContentSize2(building.width * resolutionScale, building.height * resolutionScale);
        this.setPositionRound({
            x: { align: "center", dx: building.x * resolutionScale },
            y: { align: "center", dy: building.y * resolutionScale }
        });
        this.setAnchorPoint(0.5, 0.5);
        if (this.building.customZOrder !== undefined) {
            this.setLocalZOrder(this.building.customZOrder);
        }

        var animation = this.animation = BuildingView.createView(building);
        animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(animation);

        this.addHighlightHandler = building.on("addHighlight", this.addHighlight.bind(this));
        this.removeHighlightHandler = building.on("removeHighlight", this.removeHighlight.bind(this));

        this.openBuildingHandler = building.on("openBuilding", this.openBuilding.bind(this));
        this.animateChangeStageHandler = building.on("animateChangeStage", this.animateChangeStage.bind(this));

        this.showChoiceHandler = building.on("showChoice", this.showChoice.bind(this));
        this.unshowChoiceHandler = building.on("unshowChoice", this.unshowChoice.bind(this));
    },

    onExit: function () {
        this._super();

        this.addHighlightHandler.clear();
        this.removeHighlightHandler.clear();

        this.openBuildingHandler.clear();
        this.animateChangeStageHandler.clear();

        this.showChoiceHandler.clear();
        this.unshowChoiceHandler.clear();
    },

    unshowChoice: function () {
        this.stopAllActions();
        this.setScale(1);
        this.animation.setAnimation(0, BuildingView.chooseAnimation(this.building, { prevStage: true }), true);
    },

    showChoice: function (choice) {
        this.stopAllActions();
        this.animation.setAnimation(0, BuildingView.chooseAnimation(this.building, { choice: choice }), true);

        if (!this.building.untouchable) {
            this.setScale(1);
            this.runAction(new cc.Sequence(new cc.ScaleTo(0.15, 1.1), new cc.ScaleTo(0.15, 1)));
        }
    },

    animateChangeStage: function (f) {
        this.animation.runAction(new cc.Sequence(
            new cc.DelayTime(1.8),
            new cc.CallFunc(function () {
                f();
            })
        ));
    
        this.animation.setAnimation(0, BuildingView.chooseOpenAnimation(this.building), false);
        this.animation.addAnimation(0, BuildingView.chooseAnimation(this.building), true);
    },

    openBuilding: function (f) {
        var transition = new cleverapps.Spine(bundles.metha_top_layer_0.jsons.building_transition_json);
        transition.setAnimation(0, "animation", false);
        transition.setCompleteListener(function () {
            transition.setCompleteListener();
            transition.runAction(cc.removeSelf());
        });
        transition.setPosition(this.getPosition());
        this.parent.addChild(transition, 1);

        this.animation.runAction(new cc.Sequence(
            new cc.DelayTime(1.8),
            new cc.CallFunc(function () {
                this.animation.setAnimation(0, BuildingView.chooseAnimation(this.building), true);
                f();
            }.bind(this))
        ));

        cleverapps.audio.playSound(bundles.world_map.urls.repair_sfx);
    },

    removeHighlight: function () {
        if (this.highlight) {
            this.highlight.runAction(new cc.RemoveSelf());
            delete this.highlight;
        }
    },

    addHighlight: function () {
        if (this.highlight) {
            return;
        }
        var styles = cleverapps.styles.BuildingView.current;

        var animation = new cleverapps.Spine(styles.json);
        animation.setAnimation(0, styles.animation, true);
        animation.setPosition(this.width / 2, this.height / 2);
        var scale = Math.max(Math.min(this.width / animation.width, this.height / animation.height, styles.scale.max), styles.scale.min);
        animation.setScale(scale);
        this.addChild(animation, styles.zOrder);
        this.highlight = animation;
    }
});

BuildingView.getAnimationStage = function (building) {
    return building.isComplete() ? 1 : 0;
};

BuildingView.createView = function (building, options) {
    options = options || {};
    var json = BuildingView.getJson(building);
    var scale = 1;

    if (!json) {
        throw "No json for building - " + building.id + " name - " + building.name;
    }

    if (options.scale) {
        scale = options.scale;
    } else if (options.size) {
        var viewSize = BuildingView.getSize(building, options);
        scale = Math.min(options.size.width / viewSize.width, options.size.height / viewSize.height);
    }

    var animation = new cleverapps.Spine(json);
    animation.setScale(scale);
    animation.setAnimation(0, BuildingView.chooseAnimation(building, options), true);
    return animation;
};

BuildingView.getJson = function (building) {
    var json = bundles.farm_windows.jsons["building" + building.id];

    if (!json) {
        var rooms = bundles.metha_bottom_layer_0;
        var buildings = bundles.metha_top_layer_0;

        for (var bundleId = 1; rooms || buildings; bundleId++) {
            if (rooms && rooms.jsons["building" + building.id]) {
                return rooms.jsons["building" + building.id];
            }

            if (buildings && buildings.jsons["building" + building.id]) {
                return buildings.jsons["building" + building.id];
            }

            rooms = bundles["metha_bottom_layer_" + bundleId];
            buildings = bundles["metha_top_layer_" + bundleId];
        }
    }

    return json;
};

BuildingView.chooseAnimation = function (building, options) {
    options = options || {};
    var stage = BuildingView.getAnimationStage(building);
    stage = BuildingView.validateStage(stage, building, options);

    var animation = "idle_" + stage;
    if (options.icon && building.getStageIcon(stage)) {
        animation = "icon_" + stage;
    }

    if (building.hasChoices() && building.isLastStage(stage)) {
        var choice = options.choice;
        if (choice === undefined) {
            choice = building.getChoice();
        }
        animation += "_" + choice;
    }
    return animation;
};

BuildingView.chooseOpenAnimation = function (building) {
    var animation = "open_" + BuildingView.getAnimationStage(building);
    if (building.hasChoices() && building.isLastStage()) {
        animation += "_" + building.getChoice();
    }
    return animation;
};

BuildingView.getSize = function (building, options) {
    var size = {
        width: building.width * resolutionScale,
        height: building.height * resolutionScale
    };

    var stage = BuildingView.getAnimationStage(building);
    stage = BuildingView.validateStage(stage, building, options);

    var iconSize = building.getStageIcon(stage);
    if (options.icon && iconSize) {
        size = iconSize;
    }

    return size;
};

BuildingView.validateStage = function (stage, building, options) {
    if (options.nextStage && !building.isLastStage()) {
        stage++;
    } else if (options.prevStage && stage > 0) {
        stage--;
    }
    return stage;
};

cleverapps.styles.BuildingView = {
    current: {
        json: bundles.metha_top_layer_0.jsons.current_building_under,
        animation: "animation",
        zOrder: -1,
        scale: {
            max: 1.4,
            min: 0.8
        }
    }
};